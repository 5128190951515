<div class="px-5 pb-5">
  <div class="py-5">
    <mat-card-title>{{titre}}</mat-card-title>
  </div>

    <div class="row">
      <div class="col-md-6">
        <div class="row g-0 border rounded overflow-hidden flex-md-row mb-4 shadow-sm h-md-250 position-relative">
          <div class="col p-4 d-flex flex-column position-static">
            <div class="d-flex justify-content-between">
            <strong class="d-inline-block mb-2 mt-2 text-dark">{{chart1}}</strong>
            <div class="d-inline-block">
              <mat-form-field appearance="outline">
                <mat-select placeholder="Chargé" [(ngModel)]="selectedDevice" (ngModelChange)="onChange()">
                  <mat-option *ngFor="let s of cpi " [value]="s">
                    {{s}}
                  </mat-option>
                </mat-select>
                </mat-form-field>
            </div>
           </div>
            <img [hidden]="session == 'GROUP_CHARGES' || session == 'GROUP_FINANCIERS' " src="./assets/chart1.png">
            <img [hidden]="session == 'GROUP_DIRECTEURS' " src="../../assets/chart4.png">
          </div>
        </div>
      </div>

      <div class="col-md-6">
        <div class="row g-0 border rounded overflow-hidden flex-md-row mb-4 shadow-sm h-md-250 position-relative">
          <div class="col p-4 d-flex flex-column position-static">
            <strong class="d-inline-block mb-5 mt-2 text-dark">Taux des recouvrements par mois</strong>
            <img [hidden]="session == 'GROUP_CHARGES'|| session == 'GROUP_FINANCIERS'" src="./assets/chart2.png">
            <img [hidden]="session == 'GROUP_DIRECTEURS'" src="../../assets/chart3.png">
          </div>
        </div>
      </div>
    </div>
<div [hidden]="session == 'GROUP_FINANCIERS'">
<mat-card >
  <p class="text-dark px-4 mt-2 fw-bold h5">{{tache}}</p>
  <div class="d-flex justify-content-between">
   <div class="d-flex px-1 mt-2">
    <div class="w-100 mt-3">
      <mat-radio-group [(ngModel)]="selectedPhaseNumber" (ngModelChange)=" filter_phase()"name="phase">
        <ul class="items answers-list" style="display:flex">
          <li class="answer px-4" [ngClass]="{ active: selectedPhaseNumber === i + 1 }" *ngFor="let answer of ListPhases.data; let i = index">
            <mat-radio-button disableRipple="true" [value]="answer.value">
              <p class="font-weight-normal">{{ answer.text }}</p>
            </mat-radio-button>
          </li>
        </ul>
      </mat-radio-group>
    </div>
    <button  mat-button class="btn-filter" (click)="toggle()"><mat-icon inline=true>filter_list</mat-icon> {{buttonName}}</button>
   </div>
   <div class="d-flex justify-content-between">
    <div class="d-inline-block px-2">
      <mat-form-field appearance="outline">
        <input matInput (keyup)="applyFilter($event.target.value)"  placeholder="Recherche">
        <mat-icon matSuffix >search</mat-icon>
      </mat-form-field>
    </div>

  </div>
  </div>

  <ng-container *ngIf="show" >

    <div class="d-inline-block px-2 mt-1">
      <button class="reset-btn"  mat-button  (click)="reset(reset)"><mat-icon inline=true>replay</mat-icon></button>
    </div>
    <div class="d-inline-block px-2" *ngIf="session == 'GROUP_CHARGES' ">
       <mat-form-field appearance="outline">
       <mat-select placeholder="{{filter_label}}" [(ngModel)]="selectedDevice" (ngModelChange)="onChange()">
        <mat-option *ngFor="let s of debiteur" [value]="s.nom">
          {{s.nom}} {{s.prenom}}
        </mat-option>
       </mat-select>
       </mat-form-field>
    </div>
    <div class="d-inline-block px-2"  *ngIf="session == 'GROUP_DIRECTEURS' ">
      <mat-form-field appearance="outline">
      <mat-select placeholder="{{filter_label}}" [(ngModel)]="selectedDevice" (ngModelChange)="onChange()">
       <mat-option *ngFor="let s of charges" [value]="s.agent">
         {{s.agent}}
       </mat-option>
      </mat-select>
      </mat-form-field>
   </div>

    <div class="d-inline-block px-2">
      <mat-form-field appearance="outline">
        <mat-select placeholder="Tâche à faire" [(ngModel)]="selectedDevice" (ngModelChange)="onChange()">
          <mat-option *ngFor="let s of relance " [value]="s">
            {{s}}
          </mat-option>
        </mat-select>
        </mat-form-field>
    </div>

    <div class="d-inline-block px-2">
        <mat-form-field appearance="outline">
          <mat-select placeholder="Montant" [(ngModel)]="selectedDevice" (ngModelChange)="filter_montant()">
           <mat-option *ngFor="let s of montant " [value]="s">
             {{s}}
           </mat-option>
          </mat-select>
          </mat-form-field>
     </div>


    </ng-container>



  <div class="example-container mat-elevation-z8 mt-3 ">
    <div class="table-holder">
    <mat-table #table [dataSource]="tasks" matSort>

      <ng-container matColumnDef="nomCharge">
        <mat-header-cell *matHeaderCellDef> Nom du chargé </mat-header-cell>
        <mat-cell *matCellDef="let element"><p class="fnt-10 mt-3">{{element.agent}}</p></mat-cell>
       </ng-container>

        <!-- Position Column -->
        <ng-container matColumnDef="numero">
         <mat-header-cell *matHeaderCellDef> ID du dossier </mat-header-cell>
         <mat-cell *matCellDef="let element"><p class="fnt-10 mt-3">{{element.nomDossier}}</p></mat-cell>
        </ng-container>

        <!-- Name Column -->
        <ng-container matColumnDef="nom">
         <mat-header-cell *matHeaderCellDef> Débiteur</mat-header-cell>
         <mat-cell *matCellDef="let element"><p class="fnt-10 mt-3">{{element.nom}} {{element.prenom}}</p>  </mat-cell>
        </ng-container>

        <!-- Adresse  Column -->
        <ng-container matColumnDef="phase">
         <mat-header-cell *matHeaderCellDef> Phase </mat-header-cell>
         <mat-cell *matCellDef="let element"><p class="fnt-10 mt-3">{{element.phase}}</p>  </mat-cell>
        </ng-container>


        <!-- Montant Column -->
        <ng-container matColumnDef="montant">
          <mat-header-cell *matHeaderCellDef> Montant </mat-header-cell>
          <mat-cell *matCellDef="let element"><p class="fnt-10 mt-3">{{element.montant}}</p></mat-cell>
        </ng-container>

        <!-- Adresse  Column -->
        <ng-container matColumnDef="tache">
          <mat-header-cell *matHeaderCellDef>Tâche à faire </mat-header-cell>
          <mat-cell *matCellDef="let element"><p class="fnt-10 mt-3">{{element.tache}}</p></mat-cell>
         </ng-container>

       <!--Affecter action Column -->
       <!-- <ng-container matColumnDef="note" >
         <mat-header-cell *matHeaderCellDef></mat-header-cell>
         <mat-cell *matCellDef="let element" >
          <span [hidden]="session !== 'GROUP_CHARGES'" >
            <a><mat-icon class="action-icon" matTooltip="Réclamation"> border_color</mat-icon></a>
          </span>
         </mat-cell>
       </ng-container> -->

       <!--Affecter action Column -->
       <!-- <ng-container matColumnDef="chat">
        <mat-header-cell *matHeaderCellDef></mat-header-cell>
        <mat-cell *matCellDef="let element">
          <span [hidden]="session !== 'GROUP_CHARGES'" >
            <a><mat-icon class="action-icon" matTooltip="SMS"> sms</mat-icon></a>
          </span>
        </mat-cell>
       </ng-container> -->

        <!--Affecter action Column -->
       <!-- <ng-container matColumnDef="phone">
          <mat-header-cell *matHeaderCellDef></mat-header-cell>
          <mat-cell *matCellDef="let element" >
          <span [hidden]="session !== 'GROUP_CHARGES'" >
            <a><mat-icon class="action-icon" matTooltip="Appel" > phone</mat-icon></a>
          </span>
        </mat-cell>

       </ng-container> -->

       <!--Signaler action Column -->
        <ng-container matColumnDef="consulter">
          <mat-header-cell *matHeaderCellDef>  </mat-header-cell>
          <mat-cell *matCellDef="let element"> <div class="px-1" > <button mat-button class="btn-blue" (click)="CnsulterAction(element.nomDossier)" >consulter</button> </div> </mat-cell>
       </ng-container>


      <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumns;" ></mat-row>

    </mat-table>
    <mat-paginator  [pageSizeOptions]="[2, 10, 25, 100]"></mat-paginator>
  </div>
</div>
</mat-card>
</div>


<style>
  ::ng-deep .adf-container-full-width {
  padding-top: 30px !important;
  padding-left: 20px;
  padding-right: 20px;
  }
  .action-icon{
    font-size: 17px;
    color: #1170C4;
    cursor: pointer;
    margin-top: 5px;
  }
  .action-icon:hover{
   transform: scale(1.2);
  }
  .btn-filter{
  height:38px;
  border-radius: 4px;
  border: 1px solid #ccc !important;
  color: black !important;
  }

  .enable {
  border-radius: 50% !important;
  /* background: transparent !important; */
  line-height: 34px !important;
  color: #1170C4 !important;
  width: 50% !important;
  background: #fff !important;
}

.disable {
  pointer-events: none !important;
  line-height: 34px !important;
  /* background: transparent !important; */
  color: #aaa!important;
  width: 50% !important;
  border-radius: 50% !important;;
}
.action-btn{
  border: 1px solid #1170C4;
  padding: 0px 5px 0px 5px !important;
  line-height: 34px !important;
  color: #fff !important;
  background-color:#1170C4 !important;
  width: 100% !important;
}
.reset-btn{
  min-width: 50px!important;
  border: 1px solid #073d7e;
  padding: 0px 1px 0px 1px !important;
  line-height: 34px !important;
  color: #fff !important;
  background-color:#073d7e !important;
  width: 100% !important;
  font-size: 25px;
}

.mat-column-numero{
  flex: 0 0 17%;
}
.mat-column-nbdossier{
  flex: 0 0 10%;
}
.mat-column-tache{
  flex: 0 0 16%;
}
.mat-column-phone{
  flex: 0 0 7%;
}
.mat-column-chat{
  flex: 0 0 7%;
}
.mat-column-note{
  flex: 0 0 7%;
}
.fnt-10{
  font-size: 10px;
}
</style>
